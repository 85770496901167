import React, { Component } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Col from 'react-bootstrap/Col';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import SelectCalendar from '../components/SelectCalendar';
import SelectDay from '../components/SelectDay';
import SelectTime from '../components/SelectTime';
//import Table from 'react-bootstrap/Table';
//import ViewDebitor from "./ViewDebitor";
import ViewProduct from "./ViewProduct";
import ViewService from "./ViewService";


export default class DateEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            token: null,
            isPcode: false,
            id: null,
            debitor_id: null,
            order_id: null,
            runtime: null,
            newruntime: null,
            function: null,
            status: null,
            order_sid: "",
            order_vk: "",
            info: '',
            description: '',
            pcode: '',
            timePcode: 0,
            disabledPcode: false,
            warning: '',
            latitude:null,
            longitude:null,
            toMove: null,
            dates: [],
            calendar:null,
            moveDateOld: [],
            moveDateNew: []
        }

        this.timeOut = null;

        this.runTime = [
            [30,'30 Minuten'],
            [60,'60 Minuten'],
            [90,'1,5 Stunden'],
            [120,'2 Stunden'],
            [150,'2,5 Stunden'],
            [180,'3 Stunden'],
            [210,'3,5 Stunden'],
            [240,'4 Stunden'],
            [270,'4,5 Stunden'],
            [300,'5 Stunden'],
            [330,'5,5 Stunden'],
            [360,'6 Stunden'],
            [390,'6,5 Stunden'],
            [420,'7 Stunden'],
            [450,'7,5 Stunden'],
            [480,'8 Stunden']
        ];

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goSubmit = this.goSubmit.bind(this);
        this.onSign = this.onSign.bind(this);
        this.resetPcode = this.resetPcode.bind(this);
        this.updateTime = this.updateTime.bind(this);
        this.handleCalChange = this.handleCalChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        
    }


    _fetchData = async () => {
        clearTimeout(this.timeOut);

        var body = "token=" + this.state.token;
        body += "&params[id]=" + this.state.id;
        body += "&params[hash]=" + this.state.myHash;
        

        //console.log('TerminPlaner_fetchData', Date(), body)

        const response = await fetch(localStorage.getItem('server') + '/dates/get/', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: body,
        });
        //console.log('response', response.text());
        const responseJson = await response.json();
       //console.log('responseJson', responseJson);
        
  

        if(responseJson.data &&responseJson.data !== 'nochange') {
            this.setState({ 
                isLoading: false,
                data: responseJson.data, 
                debitor_id: responseJson.data.debitor_id,
                order_sid: responseJson.data.order_sid, 
                order_vk: responseJson.data.order_vk,
                order_id: responseJson.data.order_id, 
                calendar: responseJson.data.calendar,
                time: Date.now(), 
                myHash: responseJson.parameters.dataHash,
                runtime: responseJson.data.runtime,
                moveDateOld: {
                    id: responseJson.data.id,
                    orderid: responseJson.data.order_id,
                    calendar: responseJson.data.calendar,
                    date: responseJson.data.date,
                    start: responseJson.data.start,
                    daytime: responseJson.data.daytime,
                    runtime: responseJson.data.runtime
                }
            });
        } else {

        }

        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 30000);      
        
    };

    updateTime() {
        //console.log('updateTime', this.timeOut);

        var latitude = 52.074457282619136;
        var longitude = 7.000283333974845;
        if(localStorage.getItem('clienttype') === 'mobile') {
            navigator.geolocation.getCurrentPosition(function(position) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
            });
        }

        this.setState({
            time: Date.now(),
            latitude: latitude,
            longitude: longitude,
        });   
    }

    componentDidMount() {
        //console.log('componentDidMount');
        const response = sessionStorage.getItem('myService');
        const responseJson = JSON.parse(response);
        var latitude = 52.074457282619136;
        var longitude = 7.000283333974845;
        if(localStorage.getItem('clienttype') === 'mobile') {
            navigator.geolocation.getCurrentPosition(function(position) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
            });
        }

       //console.log('componentDidMount', this.props)

      
        this.setState({
            id: this.props.element.id, 
            token: responseJson.Ergebnis,
            disabledPcode: false,
            latitude: latitude,
            longitude: longitude,
            newruntime: this.props.element.runtime,
            runtime: this.props.element.runtime,
            moveDateOld: {
                id: this.props.element.id,
                orderid: this.props.element.order_id,
                calendar: this.props.element.calendar,
                date: this.props.element.date,
                start: this.props.element.start,
                daytime: this.props.element.daytime,
                runtime: this.props.element.runtime
            }
       
        });

    }

    componentDidUpdate() {
       //console.log('componentDidUpdate');
        this._fetchData();

    }

    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }

    _setData = async () => {
        //console.log(this.setDataAdress, this.setDataBody)

        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });
        //console.log(response);
        // eslint-disable-next-line no-unused-vars
        const responseJson = await response.json();
      //console.log("_setData",responseJson);

        if(responseJson.status) {
            this.setState({
                info: '',
                warning: '',
                disabledPcode: true,
            });
        } else {
            this.setState({
                pcode: '',
                warning: <Alert color="danger">Der 7-stelligen Personal-Code ist falsch!</Alert>
            });
        }

        //window.location.reload(false);

    };

    goSubmit(event) {
        this.setDataAdress = localStorage.getItem('server') + '/dates/update';

        this.setDataBody = "params[id]=" + this.state.id;
        this.setDataBody += "&params[order_id]=" +  this.state.order_id;
        this.setDataBody += "&params[info]=" + encodeURIComponent(this.state.info);
        this.setDataBody += "&params[description]=" + encodeURIComponent(this.state.description);

        if(event.target.value === 'INFO') {
            this.setDataBody += "&params[function]=INFO";
            this.setDataBody += "&params[status]=";
        } else {
            this.setDataBody += "&params[function]=STATUS";
            this.setDataBody += "&params[status]=" + event.target.value;
        }
        if(this.props.element.order_sid !== this.state.order_sid) {
            this.setDataBody += "&params[order_sid]=" + this.state.order_sid;
        }
        if(this.props.element.order_vk !== this.state.order_vk) {
            this.setDataBody += "&params[order_vk]=" + this.state.order_vk;
        }     
        if(this.props.element.runtime !== this.state.newruntime) {
            this.setDataBody += "&params[start]=" + this.state.moveDateOld.start;
            this.setDataBody += "&params[newruntime]=" + this.state.newruntime;
        }     
        this.setDataBody += "&code=" + this.state.pcode;

       //console.log(this.setDataBody);
        event.preventDefault();
        this._setData();

    }

    onMoveDate() {

        //console.log('onMoveDate',e.dataTransfer.getData("id"), e.target.dataset.calendar, e.target.dataset);

        this.setDataAdress = localStorage.getItem('server') + '/dates/move';

        this.setDataBody  = "code=" + this.state.pcode;
        this.setDataBody += "&params[id]=" + this.state.moveDateOld.id;
        this.setDataBody += "&params[orderid]=" + this.state.moveDateOld.orderid;
        this.setDataBody += "&params[calendar]=" + this.state.moveDateOld.calendar;
        this.setDataBody += "&params[date]=" + this.state.moveDateOld.date;
        this.setDataBody += "&params[start]=" + this.state.moveDateOld.start;
        this.setDataBody += "&params[daytime]=" + this.state.moveDateOld.daytime;
        this.setDataBody += "&params[runtime]=" + this.state.moveDateOld.runtime;
        this.setDataBody += "&params[targetCalendar]=" + this.state.moveDateNew.calendar;
        this.setDataBody += "&params[targetDate]=" + this.state.moveDateNew.date;
        this.setDataBody += "&params[targetStart]=" + this.state.moveDateNew.start;
        this.setDataBody += "&params[targetDaytime]=" + this.state.moveDateNew.daytime;


        //console.log('onMoveDate', this.setDataBody);
        
        this._setData();
    }

    handleSubmit(event) {
        if(this.state.pcode.length === 7) {
            if(event.target.value === 'INFO' && this.state.info === '' && this.state.description === '' && this.props.element.order_sid === this.state.order_sid && this.props.element.order_vk === this.state.order_vk && this.state.runtime === this.state.newruntime) {
                this.setState({warning: <Alert color="warning">Keine Info gespeichert!</Alert>});
            } else if(event.target.value === '99' && this.state.info === '' && this.props.element.order_sid === this.state.order_sid && this.props.element.order_vk === this.state.order_vk && this.state.runtime === this.state.newruntime) {
                this.setState({warning: <Alert color="danger">Bitte Storno begründen!</Alert>});
            } else if(event.target.value === 'MOVE') {
                this.onMoveDate();
            } else {
                const question = {
                    '60': 'Folgetermin?',
                    '70': 'Termin abbrechen?',
                    '80': 'Termin beenden?'
                }

                if(['60','70','80'].includes(event.target.value)) {
                    var bestaetigung = window.confirm(question[event.target.value]);
                    if(bestaetigung) {
                        this.goSubmit(event);
                    }
                } else {
                    this.goSubmit(event);
                }
            }
        } else {
            this.setState({warning: <Alert color="danger">Bitte den 7-stelligen Personal-Code eingeben!</Alert>, pcode: '' });
        }

    }

    resetPcode() {
        this.setState({
            pcode: '',
            disabledPcode: false
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleCalChange(listid, id, value) {
        var dates = this.state.dates;
        //console.log('handleCalChange',dates);

        dates = value;

        //console.log(listid, id, value);

        //this.setState({dates: dates});
        this.setState({
            dates: dates,
            calendar: value
        });

    }

    handleDayChange(e) {
       /*console.log(
            e.target[e.target.value].attributes, 
            e.target[e.target.value].attributes.calid.value,
            e.target[e.target.value].attributes.date.value,
            e.target[e.target.value].attributes.daytime.value,
            e.target[e.target.value].attributes.runtime.value
            );*/
        this.setState({
            moveDateNew: {
                calendar: e.target[e.target.value].attributes.calid.value,
                date: e.target[e.target.value].attributes.date.value,
                start: null,
                daytime: e.target[e.target.value].attributes.daytime.value
            }
        })
    }

    handleTimeChange(e) {
        this.setState({
            moveDateNew: {
                calendar: this.state.moveDateNew.calendar,
                date: this.state.moveDateNew.date,
                start: e.target.value,
                daytime: this.state.moveDateNew.daytime
            }
        })
    }

    test(event) {
        //console.log('test', {[event.target.name]: event.target.value});
    }

    onSign(sign) {
        //console.log(sign);

        this.setDataAdress = localStorage.getItem('server') + '/dates/update';

    }


    render() {




        if(!this.state.isLoading) {

            const element = this.state.data;
            const user = JSON.parse(sessionStorage.getItem('myUser'));
            const cal = JSON.parse(sessionStorage.getItem('myCalendarList'));
            //const calendar = JSON.parse(sessionStorage.getItem('myCalendar'));

            // eslint-disable-next-line no-unused-vars
            const free = this.props.dFree;
    
          //console.log('DateEdit_element', this.props, this.state)
    
            const startTime = new Date(element.date + ' ' + element.start);
    
            // eslint-disable-next-line no-unused-vars
            const endTime = new Date(element.date + ' ' + element.end);
    
            var options = { weekday: 'long', day: 'numeric', month: 'long' };
        
            var startdate = startTime.toLocaleDateString("de-DE", options);
    
            const rAbteilung = ['6','8','11'];
            const myservice = JSON.parse(sessionStorage.getItem('myService'));

            const createUser = user[element.createfrom];
            //console.log(myservice);

            const daytime = {1:'Vormittag', 2:'Nachmittag'};
            var formSid = "";
            var formAuf = "";
            var formDeb = "";
            let dateMove;
            let timeOpt = false;

            if(rAbteilung.includes(myservice['MitarbeiterAbteilung']) && element.statusid < 30 && localStorage.getItem('clienttype') === 'desktop') {
                dateMove = <Button className="me-2" variant="secondary" name="toMove" value="MOVE" onClick={this.handleChange}>verschieben</Button>;
                timeOpt = true;
            } else {
                dateMove = "";
            }

            if(this.props.element.debitor_id !== '') {
                formDeb = " disabled";
            }            
            if(this.props.element.order_sid !== '') {
                formSid = " disabled";
            }
            if(this.props.element.order_vk !== '') {
                formAuf = " disabled";
            }

            //className={`h3 ex-${element.status} pt-3 pb-3`}
            var headerInfo = <p className={`h3 ex-${element.status} pt-3 pb-3`}>{cal[element.calendar].lable} | {startdate} {element.start} Uhr bis {element.end} Uhr<br /> <small>Angenommen {element.create} von {createUser.f_vorname} {createUser.f_name}</small></p>;
            //const headerLine = <h3 className="pt-2 ps-3">{startdate} {element.start} Uhr bis {element.end} Uhr</h3>; // ({element.function.stages[element.statusid].name});

            if(!rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                headerInfo = <p className="h5">{cal[element.calendar].lable} | {startdate} {daytime[element.daytime]}<br /> <small>Angenommen {element.create} von {createUser.f_vorname} {createUser.f_name}</small></p>;
            }

            var sum = {runtime: 0, aw: 0};

            let formModalFooter =   <ModalFooter>
                                        <div className="container text-center">
                                            <div className="col-12">{this.state.warning}</div>
                                            <div className="row">
                                                <div className="col-2">
                                                    {dateMove}
                                                </div>
                                                <div className="col-10" style={{justifyContent: "flex-end"}}>
                                                    <ButtonToolbar style={{justifyContent: "flex-end"}}>
                                                    <Form.Control className="me-2" type="password" name="pcode" placeholder="P-Code" autoComplete="off" value={this.state.pcode} onChange={this.handleChange} disabled={this.state.disabledPcode} style={{width:"80px"}} /> 
                                                        {
                                                            // eslint-disable-next-line array-callback-return
                                                            element.function.state.map((belement, bindex) => { //element.statusid
                                                                if((rAbteilung.includes(myservice['MitarbeiterAbteilung']) && belement.id === '60' && element.statusid === '80')) {
                                                                    if(localStorage.getItem('clienttype') === 'desktop') {
                                                                        return (
                                                                            <Button className="me-2" key={bindex} value={belement.id} variant={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}>{belement.function}</Button>
                                                                        );
                                                                    }
                                                                } else if((rAbteilung.includes(myservice['MitarbeiterAbteilung']) || belement.id === '99')) {
                                                                    if(localStorage.getItem('clienttype') === 'desktop') {
                                                                        return (
                                                                            <Button className="me-2" key={bindex} value={belement.id} variant={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}>{belement.function}</Button>
                                                                        );
                                
                                                                    } else if(localStorage.getItem('clienttype') === 'mobile' && belement.id !== '99' && belement.id !== '90') {
                                                                        return (
                                                                            <Button className="me-2" key={bindex} value={belement.id} variant={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}>{belement.function}</Button>
                                                                        );
                                
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        <Button 
                                                            variant="secondary" 
                                                            value="INFO" 
                                                            onClick={this.handleSubmit}
                                                            
                                                        >
                                                            speichern
                                                        </Button>
                                                    </ButtonToolbar>    
                                                </div>                        
                                            </div>
                                        </div>
                                    </ModalFooter>;

            if(localStorage.getItem('clienttype') === 'mobile') {
                let eButton = '';
                var eState = [];
                element.function.state.forEach(selement => {
                    if(selement.id === '60' && element.statusid === '80') {}
                    else if(selement.id === '80' && element.statusid === '60') {}
                    else if(selement.id === '99' || selement.id === '90') {
                    } else {
                        eState.push(selement);
                    }
                });
                if(eState.length === 0) {
                    eButton =   <div className="col-8">
                                </div>;
                }
                if(eState.length === 3) {
                    eButton =   <div className="col-2">
                                </div>;
                }

                formModalFooter =   <ModalFooter className="position-fixed bottom-0 end-0" style={{width:"100%"}}>
                                        <div className="container-fluid text-center">
                                            <div className="row ps-0">
                                                <div className="col-12">{this.state.warning}</div>
                                                <div className="col-2">
                                                    <Form.Control className="pt-3 pb-3" type="password" name="pcode" placeholder="P-Code" autoComplete="off" value={this.state.pcode} onChange={this.handleChange} disabled={this.state.disabledPcode} style={{width:"100%"}} /> 
                                                </div>
                                                {eButton}

                                                    {
                                                        // eslint-disable-next-line array-callback-return
                                                        eState.map((belement, bindex) => {
                                                            const xCol = "ps-1 pe-1 col-" + Math.floor(8 / eState.length);
                                                            if((rAbteilung.includes(myservice['MitarbeiterAbteilung']))) {
                                                                    return (
                                                                        <div key={bindex} className={xCol}>
                                                                            <Button className="pt-3 pb-3" key={bindex} value={belement.id} variant={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}  style={{width:"100%"}}>{belement.function}</Button>
                                                                        </div>  
                                                                    );
                            
                                                            }
                                                        })
                                                    }
                                                <div className="col-2">
                                                    <Button className="me-2 pt-3 pb-3" variant="secondary" value="INFO" onClick={this.handleSubmit} style={{width:"100%"}} >speichern</Button>
                                                </div>            
                                            </div>          
                                        </div>
                                    </ModalFooter>;                
            }

            if(this.state.toMove) {

                const openday = element.openday;
                const opentime = element.opentime;

                formModalFooter =   <ModalFooter>
                                        <div className="container text-center">
                                            <div className="col-12">{this.state.warning}</div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <SelectCalendar handleCalChange={this.handleCalChange} bsSize="default" id={element.date_id} cal={element.calendar} />
                                                </div>
                                                <div className="col-4">
                                                    <SelectDay handleDayChange={this.handleDayChange} bsSize="default" id={element.date_id} runtime={element.runtime} openday={openday[this.state.calendar]} cal={this.state.calendar} />
                                                </div>
                                                <div className="col-2">
                                                    <SelectTime handleTimeChange={this.handleTimeChange} bsSize="default" opentime={opentime} datanew={this.state.moveDateNew} />
                                                </div>                                    
                                                <div className="col-4" style={{justifyContent: "flex-end"}}>
                                                    <ButtonToolbar style={{justifyContent: "flex-end"}}>

                                                        <Form.Control className="me-2" type="password" name="pcode" placeholder="P-Code" autoComplete="off" value={this.state.pcode} onChange={this.handleChange} disabled={this.state.disabledPcode} style={{width:"80px"}} /> 
                                                        <Button variant="secondary" value="MOVE" onClick={this.handleSubmit}>verschieben</Button>
                                                    </ButtonToolbar>    
                                                </div>                        
                                            </div>
                                        </div>
                                    </ModalFooter>;
            }


               //console.log(element, this.state.moveDateOld, this.state.moveDateNew);
            return (
                <Form style={{height:"100%"}}>
                    <ModalHeader className={`ex-${element.status} pt-1 pb-0`}  closeButton>
                        {headerInfo}
                    </ModalHeader>
                    <ModalBody className="pt-2 ">
    
                        <Row>
                            <Col className="col-2">
                                <FormGroup>
                                    <Form.Label>Kunden Nr.</Form.Label>
                                    <Form.Control type="text" name="debitor_id" placeholder="Kunden Nr." style={{borderColor: "orange"}} onBlur={this.test} onChange={this.handleChange} value={this.state.debitor_id} disabled={formDeb}/>
                                </FormGroup>
                            </Col>                            
                            <Col className="col-2">
                                <FormGroup>
                                    <Form.Label>Service ID</Form.Label>
                                    <Form.Control type="text" name="order_sid" placeholder="Service ID" style={{borderColor: "orange"}} onBlur={this.test} onChange={this.handleChange} value={this.state.order_sid} disabled={formSid}/>
                                </FormGroup>
                            </Col>
                            <Col className="col-2">
                                <FormGroup>
                                    <Form.Label>Auftrag ID</Form.Label>
                                    <Form.Control type="text" name="order_vk" placeholder="Auftrag ID" style={{borderColor: "orange"}} onBlur={this.test} onChange={this.handleChange} value={this.state.order_vk} disabled={formAuf}/>
                                </FormGroup>
                            </Col>

                            <Col className="col-2">
                                <FormGroup>
                                    <Form.Label>Zeit</Form.Label>

                                                <Form.Select
                                                    size="md"
                                                    name="newruntime"
                                                    type="select"
                                                    onChange={this.handleChange}
                                                    style={{borderColor: "orange"}}
                                                    value={this.state.newruntime}
                                                    disabled={!timeOpt}
                                                    >
                                                    {
                                                        // eslint-disable-next-line array-callback-return
                                                        this.runTime.map((tElement, tIndex) => {
                                                            const maxTimeOpen = 30;
                                                            if(tElement[0] <= this.props.maxTime && tElement[0] >= maxTimeOpen) {
                                                                return (
                                                                    <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                                )                                    
                                                            } else {
                                                                if(this.props.maxTime === undefined) {
                                                                    return (
                                                                        <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                                    )                                        
                                                                }
                                                            }
                                                        })
                                                    }               
                                                </Form.Select>
                                            </FormGroup>
                            </Col>                            
                            <Col className="col-1"> 
                            </Col>
                            <Col className="col-3">
                                <Form.Label>Status</Form.Label>
                                <FormGroup>
                                    <Button className="me-2" variant={element.function.stages[element.statusid].color_wert} disabled style={{width:'100%'}}>{element.function.stages[element.statusid].name}</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        {/* <ViewDebitor formData={element} /> */}

                        <Row>
                            <Col className="col-7">
                                <h6>{element.name}</h6>
                            </Col>
                            <Col className="col-2">
                                <h6>Telefon</h6>
                            </Col>
                            <Col className="col-3">
                                <a href={element.telefonLink}><h6>{element.telefon}</h6></a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-7">
                                <h6>{element.street}</h6>
                            </Col>
                            <Col className="col-2">
                                <h6>Mobil</h6>
                            </Col>
                            <Col className="col-3">
                                <a href={element.mobilLink}><h6>{element.mobil}</h6></a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-7">
                                <h6>{element.zip} {element.city}</h6>
                            </Col>
                            <Col className="col-2">
                                <h6>E-Mail</h6>
                            </Col>
                            <Col className="col-3">
                                <h6>{element.mailadress}</h6>
                            </Col>
                        </Row>
                        <hr />
                        {
                            element.artikel.length === 0 
                                ? <ViewService formData={element} onHandleChange={this.handleChange} description={this.state.description} disabled={this.state.info === '' ? false : true} /> 
                                : <ViewProduct formData={element} />
                        }


                        <hr />
                        <h5>Auftrag Daten</h5>
                        <Row>
                            <Col>Datum</Col>
                            <Col className='text-end'>Ankunft/Beginn</Col>
                            <Col className='text-end'>Abfahrt/Fertigstellung</Col>
                            <Col className='text-end'>Zeit (Pause)</Col>
                            <Col className='text-end'>AW</Col>
                        </Row>
                        {
                            element.stemphistory.map((history, i) => {
                                //console.log(calendar.some((cal) => cal));
                                sum.runtime += history.runtime;
                                sum.aw += history.aw;
                                return(
                                    <Row key={i}>
                                        <Col>{history.order_date}</Col>
                                        <Col className='text-end'>{history.stempstart}</Col>
                                        <Col className='text-end'>{history.stempend}</Col>
                                        <Col className='text-end'>{history.runtime} min {history.pause === 0 ? '' : '(' + history.pause + ' min)'}</Col>
                                        <Col className='text-end'>{history.aw}</Col>
                                    </Row>
                                )
                            })
                        }
                        <Row>
                            <Col></Col>
                            <Col><hr /></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col className='text-end'></Col>
                            <Col className='text-end'>Summe</Col>
                            <Col className='text-end'>{sum.runtime} min</Col>
                            <Col className='text-end'>{sum.aw}</Col>
                        </Row>
                        <hr />
                        <p className="h5">Info <small>(nicht auf Kundeauftrag)</small></p>
                            {
                                element.infos.map((belement, bindex) => {
                                    var createElementUser;
                                    if(user[belement.pnr]) {
                                        createElementUser = user[belement.pnr];
                                    } else {
                                        createElementUser = {namekurz:'unbekannt'};
                                    }
                                    return (
                                        <Row key={bindex}>
                                            <Col className="col-4">{belement.created} ({createElementUser.namekurz})</Col>
                                            <Col className="col-8">{belement.info}</Col>
                                        </Row>
                                    );
                                })
                            }
                        <hr />
                        <Row>
                            <Form.Group >
                                <Form.Control 
                                    as="textarea" 
                                    name="info" 
                                    value={this.state.info} 
                                    placeholder="Interne Infos" 
                                    onChange={this.handleChange} 
                                    style={{minHeight: "100px",borderColor: "orange"}}
                                    className="form-control-lg mb-0 pb-0 pt-0 form-control"         
                                    disabled={this.state.description === '' ? false : true}                           
                                    />
                                
                            </Form.Group>
                        </Row>
                    </ModalBody>
                    {formModalFooter}
                </Form>
            )


        } else {
            return (<Alert color="danger">Leider ist die Verbindung verloren gegangen oder ein Timeout aufgetreten.<br /><Button onClick={() => {window.location.reload(false)}} >RELOAD</Button></Alert>)
        }

    }
}

/* value={this.state.pcode}  onChange={this.handleChange}*/

/*

                        <Row>
                            <Col>
                                <Table  className="m-2" striped bordered hover >
                                    <thead className="mb-2" style={{height: '20px'}}>
                                        <tr>
                                        <th style={{width: '100px'}}>Artikel</th>
                                        <th style={{width: '250px'}}>Bezeichnung</th>
                                        <th style={{width: '60px'}} className="text-center">Menge</th>
                                        <th>Info</th>
                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-truck h3"></i></th>
                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-wrench-adjustable-circle h3"></i></th>
                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-plugin h3"></i></th>
                                        <th style={{width: '50px'}} className="text-center"><i className="bi bi-recycle h3"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            element.artikel.map((belement, bindex) => {

                                                
                                                // json string to array nicht zu objekt
                                                const optData = JSON.parse(belement.optData);
                                               //console.log(optData);
                                                return (
                                                    <tr key={bindex}>
                                                        <td>{belement.artnr}</td>
                                                        <td>{belement.name}</td>
                                                        <td className="text-center">{belement.quantity}</td>
                                                        <td>
                                                        {
                                                                Object.keys(optData).map((celement, cindex) => {
                                                                   //console.log(celement, cindex);
                                                                    return (
                                                                        <div>{celement}: {optData[celement]}</div>
                                                                    );
                                                                })
                                                            }
                                                                </td>
                                                                <td className="text-center"><Form.Check name="dispose" type="checkbox" defaultChecked={belement.truck} disabled /></td>
                                                                <td className="text-center"><Form.Check name="dispose" type="checkbox" defaultChecked={belement.wrench} disabled /></td>
                                                                <td className="text-center"><Form.Check name="dispose" type="checkbox" defaultChecked={belement.plugin} disabled /></td>
                                                                <td className="text-center"><Form.Check name="dispose" type="checkbox" defaultChecked={belement.recycle} disabled /></td>
                                                            </tr>
                                                        );
                                                    })
                                                }
        
                                            </tbody>
                                        </Table>                            
                                    </Col>
                                </Row>




                        <ModalFooter>
                            <div className="container text-center">
                                <div className="col-12">{this.state.warning}</div>
                                <div className="row">
                                    <div className="col-2">
                                        {dateMove}
                                    </div>
                                    <div className="col-10" style={{justifyContent: "flex-end"}}>
                                        <ButtonToolbar style={{justifyContent: "flex-end"}}>
                                        <Form.Control className="me-2" type="password" name="pcode" placeholder="P-Code" autoComplete="off" value={this.state.pcode} onChange={this.handleChange} disabled={this.state.disabledPcode} style={{width:"80px"}} /> 
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                element.function.state.map((belement, bindex) => { //element.statusid
                                                    if((rAbteilung.includes(myservice['MitarbeiterAbteilung']) && belement.id === '60' && element.statusid === '80')) {
                                                        if(localStorage.getItem('clienttype') === 'desktop') {
                                                            return (
                                                                <Button className="me-2" key={bindex} value={belement.id} color={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}>{belement.function}</Button>
                                                            );
                                                        }
                                                    } else if((rAbteilung.includes(myservice['MitarbeiterAbteilung']) || belement.id === '99')) {
                                                        if(localStorage.getItem('clienttype') === 'desktop') {
                                                            return (
                                                                <Button className="me-2" key={bindex} value={belement.id} color={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}>{belement.function}</Button>
                                                            );
                    
                                                        } else if(localStorage.getItem('clienttype') === 'mobile' && belement.id !== '99' && belement.id !== '90') {
                                                            return (
                                                                <Button className="me-2" key={bindex} value={belement.id} color={belement.color} onClick={this.handleSubmit} disabled={this.state.isPcode}>{belement.function}</Button>
                                                            );
                    
                                                        }
                                                    }
                                                })
                                            }
                                            <Button value="INFO" onClick={this.handleSubmit}>speichern</Button>
                                        </ButtonToolbar>    
                                    </div>                        
                                </div>
                            </div>
                        </ModalFooter>

                                        return (
                    <Form>
                        <ModalHeader toggle={this.props.ontoggle}>
                            {headerInfo}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col className="col-3">
                                    <FormGroup>
                                        <Form.Label for="debitor_id">Kunden Nr.</Form.Label>
                                        <Form.Control type="text" name="debitor_id" placeholder="Kunden Nr." style={{borderColor: "orange"}} onBlur={this.test} onChange={this.handleChange} value={this.state.debitor_id} disabled={formDeb}/>
                                    </FormGroup>
                                </Col>                            
                                <Col className="col-3">
                                    <FormGroup>
                                        <Form.Label for="order_sid">Service ID</Form.Label>
                                        <Form.Control type="text" name="order_sid" placeholder="Service ID" style={{borderColor: "orange"}} onBlur={this.test} onChange={this.handleChange} value={this.state.order_sid} disabled={formSid}/>
                                    </FormGroup>
                                </Col>
                                <Col className="col-3">
                                    <FormGroup>
                                        <Form.Label for="order_vk">Auftrag ID</Form.Label>
                                        <Form.Control type="text" name="order_vk" placeholder="Auftrag ID" style={{borderColor: "orange"}} onBlur={this.test} onChange={this.handleChange} value={this.state.order_vk} disabled={formAuf}/>
                                    </FormGroup>
                                </Col>
                                <Col className="col-3">
                                    <Form.Label for="status">Status</Form.Label>
                                    <FormGroup>
                                        <Button className="me-2" color={element.function.stages[element.statusid].color_wert} disabled style={{width:'100%'}}>{element.function.stages[element.statusid].name}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col className="col-7">
                                    <h6>{element.name}</h6>
                                </Col>
                                <Col className="col-2">
                                    <h6>Telefon</h6>
                                </Col>
                                <Col className="col-3">
                                    <a href={element.telefonLink}><h6>{element.telefon}</h6></a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-7">
                                    <h6>{element.street}</h6>
                                </Col>
                                <Col className="col-2">
                                    <h6>Mobil</h6>
                                </Col>
                                <Col className="col-3">
                                    <a href={element.mobilLink}><h6>{element.mobil}</h6></a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-7">
                                    <h6>{element.zip} {element.city}</h6>
                                </Col>
                                <Col className="col-2">
                                    <h6>E-Mail</h6>
                                </Col>
                                <Col className="col-3">
                                    <h6>{element.mailadress}</h6>
                                </Col>
                            </Row>
                            <hr />
                            <p className="h5">Auftrag</p>
                            <Row>
                                <Col>{element.order}</Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>Datum</Col>
                                <Col className='text-end'>Ankunft/Beginn</Col>
                                <Col className='text-end'>Abfahrt/Fertigstellung</Col>
                                <Col className='text-end'>Zeit</Col>
                                <Col className='text-end'>AW</Col>
                            </Row>                        
                            {
                                element.stemphistory.map((history, i) => {
                                    //console.log(calendar.some((cal) => cal));
                                    sum.runtime += history.runtime;
                                    sum.aw += history.aw;
                                    return(
                                        <Row key={i}>
                                            <Col>{history.order_date}</Col>
                                            <Col className='text-end'>{history.stempstart}</Col>
                                            <Col className='text-end'>{history.stempend}</Col>
                                            <Col className='text-end'>{history.runtime} min</Col>
                                            <Col className='text-end'>{history.aw}</Col>
                                        </Row>
                                    )
                                })
                            }
                            <Row>
                                <Col></Col>
                                <Col><hr /></Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col className='text-end'></Col>
                                <Col className='text-end'>Summe</Col>
                                <Col className='text-end'>{sum.runtime} min</Col>
                                <Col className='text-end'>{sum.aw}</Col>
                            </Row>
                            <hr />
                            <p className="h5">Info <small>(nicht auf Kundeauftrag)</small></p>
                                {
                                    element.infos.map((belement, bindex) => {
                                        var createElementUser;
                                        if(user[belement.pnr]) {
                                            createElementUser = user[belement.pnr];
                                        } else {
                                            createElementUser = {namekurz:'unbekannt'};
                                        }
                                        return (
                                            <Row key={bindex}>
                                                <Col className="col-4">{belement.created} ({createElementUser.namekurz})</Col>
                                                <Col className="col-8">{belement.info}</Col>
                                            </Row>
                                        );
                                    })
                                }
                            <hr />
                            <Row>
                                <FormGroup className="mb-3">
                                    <Form.Control type="textarea" name="info"  placeholder="Infos" value={this.state.info} onChange={this.handleChange} style={{minHeight: "100px",borderColor: "orange"}} />
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        {formModalFooter}
                    </Form>
                )   

*/