import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { nl2br } from 'react-js-nl2br';

export default function ViewDebitor(props) {

    const formData = props.formData;

    return (
        <>
        <Row>
            <h5>Fehler / Auftrag / Absprachen</h5>
            <p className='h4'>{nl2br(formData.description || formData.order_info)}</p>
            <Col md={12}>
                <Form.Control 
                    className="form-control-lg mb-0 pb-0 pt-0 invisible"
                    as="textarea" 
                    name="description"  
                    placeholder="Auftragsänderungen, Absprachen, Fehlerbeschreibung" 
                    value={props.description} 
                    style={{minHeight: "60px",borderColor: "orange"}} 
                    onChange={(e) => {
                        props.onHandleChange(e);
                    }}
                    disabled={props.disabled}
                />
            </Col>
        </Row>
        </>
    )

}
