import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

import Modal from 'react-bootstrap/Modal';
import DateEdit from '../forms/DateEdit';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TerminWorkorder from '../components/TerminWorkorder';

const ExModalDate = (props) => {
  //console.log("modals/ModalDate");
    const {
      className
    } = props;
  
    const [show, setModal] = useState(false);
    const toggle = () => setModal(!show);

    const [modalWorkorder, setModalWorkorder] = useState(false);
    const toggleWorkorder = () => {
      setModal(false);
      setModalWorkorder(!modalWorkorder);
    };

    const tStart = {
      "08:00": 5,
      "08:30": 6,
      "09:00": 7,
      "09:30": 8,
      "10:00": 9,
      "10:30": 10,
      "11:00": 11,
      "11:30": 12,
      "12:00": 13,
      "12:30": 14,
      "13:00": 18,
      "13:30": 19,
      "14:00": 20,
      "14:30": 21,
      "15:00": 22,
      "15:30": 23,
      "16:00": 24,
      "16:30": 25,
      "17:00": 26,
      "17:30": 27,
      "18:00": 28,
      "18:30": 29,
      "19:00": 30,      
    };
  
    const element = props.element;
    const free = props.dFree;

   //console.log(element);
    const rAbteilung = ['6','8','11'];


    let start, end, classStatus;
    if(props.xSplit) {
      start = props.gridRowStart;
      end = props.gridRowEnd;
      classStatus = props.xSplit + ' ' + element.status;
    } else {
      // eslint-disable-next-line no-unused-vars
      start = tStart[element.start];
      // eslint-disable-next-line no-unused-vars
      end = tStart[element.end];
      classStatus = element.status;
    }
    

    function handleDragStart(e) {
      props.handleFrom(e);
      e.dataTransfer.setData("id", element.id);
      e.dataTransfer.setData("orderid", element.order_id);
      e.dataTransfer.setData("date", element.date);
      e.dataTransfer.setData("start", element.start);
      e.dataTransfer.setData("calendar", element.calendar);
      e.dataTransfer.setData("daytime", element.daytime);
      e.dataTransfer.setData("runtime", element.runtime);
    }

    function handleDragOver(e) {
      var el = document.getElementById(e.target.id);
      el.classList.add('plan-danger')
      //console.log("Date DragOver to", e.target.dataset, e.target.id, e);
      e.preventDefault();
    }

    function handleDragLeave(e) {
      var el = document.getElementById(e.target.id);
      el.classList.remove('plan-danger')
    }

    function handleDrop(e) {
      //console.log("Date Drop to")
      props.handleToId(e);
      var el = document.getElementById(e.target.id);
      el.classList.remove('plan-danger')
    }


    var paid = '';
    let sign = '';
    var ordersid = '';


    //console.log("ModalDate", element);
    if( props.modtyp === "planner") {
      var eName = element['name'];
      if(element['name'].length > 40) {
        eName = element['name'].slice(0, 37) + '...';
      }
      const xH = element.gridEnd - element.gridStart;
      //console.log(element.gridEnd - element.gridStart);
      const itemDiv = {
        display: 'grid',
        gridRowStart: props.gridStart,  
        gridRowEnd: props.gridEnd,  
        gridColumnStart: props.gridColumnStart, 
        gridColumnEnd: props.gridColumnEnd,
        //gridColumnStart: props.colStart,
        //gridColumnEnd: gridColumnEnd,         
        maxHeight: (props.xHeight + (xH * - 3.5)) + "px",
        width: '100%',
        overflow: "hidden",
        userSelect:'none',

      };
      //        maxHeight: props.xHeight + "px", 'h' + props.start.replace(":", "")
      var isDrageble = true;
      if((element.statusid >= 40  && element.statusid !== "95") || !rAbteilung.includes(props.myservice['MitarbeiterAbteilung'])) {
        isDrageble = false;
      } 

      if(element.order_data.closedOrder !== null) {
        paid = <i className="bi bi-check-circle position-absolute top-0 end-0 pt-2 pe-5 h4"></i>;
      }
      
      if(element.sign !== null) {
        sign = <i className="bi bi-info-circle-fill position-absolute top-0 end-0 p-2 h4"  onClick={toggleWorkorder}></i>;
      } else if(element.sign === null && element.order_data.closedOrder !== null) {
        sign = <i className="bi bi-info-circle position-absolute top-0 end-0 p-2 h4"  onClick={toggleWorkorder}></i>;      
      } else {
        sign = <i className="bi bi-question-circle position-absolute top-0 end-0 p-2 h4"  onClick={toggleWorkorder}></i>;

      }


      if(element.order_sid !== '') {
        ordersid = 'SID ' + element.order_sid;
      }

      return (
        <>
          <div 
            draggable={isDrageble}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            key={'date' + element.id} 
            id={element.id} 
            data-typ='quelle'
            

            data-calendar={element.calendar}
            data-time={element.gridStart}
            data-date={element.date}
            data-terminid={element.id} 
            className={"plan " + classStatus  + " position-relative "  +  props.doubleDate} 
            style={itemDiv} >
              <Row style={{maxHeight: (props.xHeight + (xH * - 3.5)) + "px",width: '100%', overflow: "hidden", userSelect:'none'}}>
                <Col xs={11} onClick={toggle} style={{maxHeight: (props.xHeight + (xH * - 3.5)) + "px"}} >{ordersid} {eName}<br/> {element['street']}<br/> {element['city']} </Col>
                <Col xs={1} style={{maxHeight: (props.xHeight + (xH * - 3.5)) + "px"}}>{paid} {sign}</Col>
              </Row>
          </div>
          <Modal 
            fullscreen='xl-down'
            size='xl' 
            show={show} 
            onHide={toggle} 
            className={className} 
            backdrop="static"
            keyboard={false}
          >
            <DateEdit ontoggle={toggle} element={element} dFree={free} myservice={props.myservice} onClosed={props.onDatesInsert} />
          </Modal>
          <Modal
            fullscreen='xl-down'
            size='xl'
            show={modalWorkorder}
            className={className}
            onHide={toggleWorkorder}
            onExit={props.loadData}
            >
                <TerminWorkorder ontoggle={toggleWorkorder} element={element} calendardate={props.calendardate} onExit={props.loadData} />
            </Modal>
        </>

      );
    } else if( props.modtyp === "listWorkSearch") {
     //console.log("listWorkSearch", element);
      return(
        <>
          <tr
          onClick={toggle}
          >
              <td>#{element.id}</td>
              <td>{element.name}</td>
              <td>{element.street}, {element.zip} {element.city}</td>
              <td>{element.order}</td>
              <td>{element.order_date}</td>
              <td className={element.status}>{element.function.stages[element.statusid].name}</td>
          </tr>
          <Modal 
            size='xl' 
            show={show} 
            onHide={toggle} 
            className={className}  
            onClosed={props.onDatesInsert}
            backdrop="static"
            keyboard={false}
          >
            <DateEdit ontoggle={toggle} element={element} onUpdate={props.onUpdate}/>
          </Modal>
        </>
      );
    } else if( props.modtyp === "calendarMonth") {
      //console.log("listWorkSearch", element);

      if(element.statusid === '90') {
        paid = <i className="bi bi-check-circle position-absolute top-0 end-0 p-2 h4"></i>;
      }

      if(element.order_sid !== '') {
        ordersid = <div>SID {element.order_sid}</div>;
      }

      return(
          <>
            <ListGroup.Item
              draggable={isDrageble}
              onClick={toggle}
              href="#"
              tag="a"
              className={props.aColor}
            >
              <div className='row'>
                <div className='col-3'>{element.start}<br />{element.end}</div>
                <div className='col'>{ordersid} {props.name} {paid}</div>
              </div>
            </ListGroup.Item>
            <Modal 
              size='lg'
              show={show}
              onHide={toggle}
              className={className}
              onClosed={props.onDatesInsert}
              backdrop="static"
              keyboard={false}
            >
              <DateEdit ontoggle={toggle} element={element} onUpdate={props.onUpdate}/>
            </Modal>
          </>
      );
    } else {
      return (
        <></>
      )
    }

  }


export default ExModalDate;