import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import DateEdit from '../forms/DateEdit';
import TerminWorkorder from '../components/TerminWorkorder';



const ExModalWorkorder = (props) => {

    const {
        className
    } = props;
    
    const [modalWorkorder, setModalWorkorder] = useState(false);
    const toggleWorkorder = () => setModalWorkorder(!modalWorkorder);

    const [modalDate, setModalDate] = useState(false);
    const toggleDate = () => setModalDate(!modalDate);

    const element = props.element;

   //console.log('ExModalWorkorder', element);

    var mapsLink = `maps://?q=${element['zip']},${element['city']},${element['street']}&views=satellite,traffic&zoom=30`;

    const s1class = "col-md-1 col-sm-2 text-center ex-" + element.status + " test";

    var stempEnd = true;

    if(element.stempend !== "") {
        stempEnd = false;
    }

    //console.log("modalDate", modalDate, "modalWorkorder", modalWorkorder, "stempEnd", stempEnd)

    return (
        
        <ListGroup.Item style={{margin: '0 !important', padding: '0 !important'}}>
            <div className="row" style={{ margin: '0 !important',padding: '0 !important'}}>

                <div className={s1class}>
                    <strong>{element.start}</strong><br/> 
                    <i className="bi bi-arrow-down"></i><br/> 
                    <div>{element.end}</div>
                </div>

                <div className="col-md-7 col-sm-7" onClick={toggleDate} >
                    {element.order_sid} {element['name']}<br/> 
                    {element['street']}<br/> 
                    {element['zip']} {element['city']}
                </div>

                    <Button
                        className="col-2 pt-3 pb-3"
                        color="primary"
                        onClick={toggleWorkorder}
                        disabled={stempEnd}
                    >
                        <i className="bi bi-pen"></i>
                    </Button>
                    <Button
                        className="col-2 pt-3 pb-3"
                        href={mapsLink} 
                        target="navi"
                    >
                        <i className="bi bi-pin-map"></i>
                    </Button>        
            </div>
            <Modal fullscreen show={modalWorkorder} className={className} onHide={toggleWorkorder} onExit={props.loadData}>
                <TerminWorkorder ontoggle={toggleWorkorder} element={element} calendardate={props.calendardate} onExit={props.loadData} onHide={props.onClosedModal} />
            </Modal>
            <Modal fullscreen show={modalDate} className={className} onHide={toggleDate}>
                <DateEdit ontoggle={toggleDate} element={element} onClick={props.removeTimer} />
            </Modal>
        </ListGroup.Item>
        
    );
}

export default ExModalWorkorder;